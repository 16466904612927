import React from "react";
import serviceone from "../../../static/images/serviceone.png";
import Risk from "../../../static/images/risk.jpg";

export default function sectionone() {
  return (
    <div className="" style={{ fontFamily: "poppins" }}>
      {/* <section
        class=" px-12 lg:py-16 lg:px-20 bg-white overflow-hidden"
        style={{ fontFamily: "poppins" }}
      >
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap xl:items-center -m-8">
            <div class="w-full md:w-1/2 p-8">
              <img
                class="pt-12 lg:pt-4 lg:mx-auto transform hover:translate-y-3 transition ease-in-out lg:w-3/4 lg:h-3/4 duration-1000"
                src={Business}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/2 p-8">
              <div class="md:max-w-xl">
                <p class="mb-2 text-sm text-indigo-900 font-semibold uppercase tracking-px">
                  &#x1F44B; Our Services
                </p>
                <h2 class=" mb-16 text-2xl md:text-4xl font-bold text-indigo-800 font-heading tracking-px-n leading-tight">
                  Enterprise Risk Management
                </h2>
                <div class="flex flex-wrap mb-5 pb-10 -m-8">
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Business Strategy
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            Evaluating conditions and strategizing Business for
                            our clients
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16 8V16M12 11V16M8 14V16M6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Product designing
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            Knowing the target audience and their needs become
                            the first step of product designing.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Impact Analysis
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            Analysis of product and market conditions to derive
                            business & capital risks.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Product Experience
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            Setting up framework to evaluate and monitor product
                            experience
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
      
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section class=" py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-7xl mx-auto">
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            {/* <!-- Text Section --> */}
            <div class="bg-white overflow-hidden p-6">
              <h2 class="text-base font-semibold leading-7 text-indigo-600 mt-10">
                Our Services
              </h2>
              {/* <h3 class="mt-2 text-3xl font-semibold tracking-tight text-blue-900">
                Risk Management Solutions
              </h3> */}
              <h3 class="mt-2 text-3xl font-semibold tracking-tight text-blue-900">
                Enterprise Risk Management (ERM) and ORSA
              </h3>
              <p class="lg:text-lg leading-8 text-gray-600 mt-10">
                In an increasingly complex business environment, managing risks
                effectively is critical to sustaining growth and resilience. At
                RJ Actuaries and Consultants, we offer holistic Enterprise Risk
                Management (ERM) solutions that help organizations identify,
                assess, mitigate, and monitor risks across all areas of
                operations.
              </p>
              {/* <p class="text-gray-600 mb-4">We offer services related to:</p> */}
              {/* <ul class="list-disc ml-6">
                <li>Risk Identification</li>
                <li>Risk Assessment</li>
                <li>Risk Modelling</li>
                <li>Risk Mitigation</li>
                <li>Risk Monitoring</li>
                <li>Alternative Risk Transfer Consulting</li>
                <li>Development of Risk Management Frameworks</li>
              </ul> */}
            </div>

            {/* <!-- Placeholder for Image Section (if needed) --> */}
            <div class="bg-white rounded-lg overflow-hidden shadow-lg">
              <img
                class="w-full h-full lg:mx-auto rounded-lg shadow-lg"
                src={Risk}
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8 md:mt-20">
              <div class="max-w-7xl mx-auto">
                <div class="text-center">
                  <h2 class="text-3xl font-bold text-blue-900 mb-6">
                    Our Services
                  </h2>
                 
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                   
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Risk Identification and Assessment                      </h3>
                      <p class="text-gray-600">
                      Comprehensive risk audits to pinpoint potential threats and vulnerabilities within your organization, including operational, financial, and market risks.          </p>
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
              
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Risk Modeling and Mitigation
                      </h3>
                      <p class="text-gray-600">
                      Develop predictive models to evaluate risk exposure and design mitigation strategies that safeguard business operations and financial stability.          </p>
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Alternative Risk Transfer Consulting
                      </h3>
                      <p class="text-gray-600">
                      Expertise in structuring alternative risk transfer mechanisms, such as captive insurance, to manage risks effectively and economically.

                      </p>
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                  
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Risk Monitoring Frameworks
                      </h3>
                      <p class="text-gray-600">
                      Implementation of robust risk monitoring systems to ensure ongoing assessment and adaptation to changing risk landscapes.
                      </p>
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                  
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Scenario Analysis and Stress Testing
                      </h3>
                      <p class="text-gray-600">
                      Evaluate organizational resilience through detailed scenario planning and stress testing for worst-case events.
                      </p>
                    </div>
                    
                  </div>
                  {/* <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                 
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Customized Benefit Plan Design
                      </h3>
                      <p class="text-gray-600">
                      Developing sustainable and meaningful benefit plans tailored to specific organizational needs and budgets.
                      </p>
                    </div>
                    
                  </div> */}
                </div>
              </div>
            </div>
            <div class="container px-4 mx-auto mt-4">
          <p class="mb-6 text-xl text-indigo-600 text-center font-semibold uppercase tracking-px">
            Why Chose RJAC ?
          </p>
          

          <section class="bg-white pb-6">
            {/* <div class="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <h1 class="md:text-2xl md:mb-5">
                    General and Health Insurance Consulting
                  </h1>
                  <p class="text-sm mb-3 font-semibold">
                    We provide a range of services to Life Insurance companies,
                    these include
                  </p>
                  <ul>
                    <li>- Capital and Risk Modelling</li>
                    <li>- Product design</li>
                    <li>- Pricing</li>
                    <li>- Reserving</li>
                    <li>- Regulatory & compliance reporting</li>
                    <li>- Liability Adequacy Testing</li>
                    <li>- Financial Condition Reports</li>
                    <li>
                      - Reinsurance optimization, appropriateness and
                      sufficiency assessment
                    </li>
                    <li>- IFRS consulting</li>
                    <li>- Financial Due-Diligence services</li>
                  </ul>
                </div>
                <div>
                  <h1 class=" md:text-2xl md:mb-5">
                    Life Insurance Consulting
                  </h1>
                  <p class="text-sm mb-3 font-semibold">
                    We provide a range of services to Life Insurance companies,
                    these include
                  </p>
                  <ul>
                    <li>- Risk Assessment</li>
                    <li>- Product design & filing</li>
                    <li>- Pricing</li>
                    <li>- Financial Reporting</li>
                    <li>- Regulatory & compliance reporting</li>
                    <li>- Reinsurance optimization</li>
                    <li>- Analysis of Surplus calculations</li>
                    <li>- Embedded value calculations</li>
                    <li>- IFRS consulting</li>
                    <li>- Financial Due-Diligence services</li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div class="max-w-7xl mx-auto">
              

              <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8">
                {/* <!-- General and Health Insurance Consulting --> */}
                <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                  <div class="p-6">
                    <h3 class="text-xl font-semibold text-blue-900 mb-4">
                    Proactive Risk Management
                    </h3>
                    <ul class="list-disc ml-6">
                      <li> Stay ahead of emerging risks with customized frameworks and actionable insights.</li>
                      
                    </ul>
                  </div>
                </div>
                <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                  <div class="p-6">
                    <h3 class="text-xl font-semibold text-blue-900 mb-4">
                    Expert Guidance
                    </h3>
                    <ul class="list-disc ml-6">
                      <li>Benefit from RJAC’s actuarial and data-driven expertise to enhance your organization’s risk profile.</li>
                      
                    </ul>
                  </div>
                </div>
                <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                  <div class="p-6">
                    <h3 class="text-xl font-semibold text-blue-900 mb-4">
                    Tailored Solutions
                    </h3>
                    <ul class="list-disc ml-6">
                      <li>We understand that every business is unique, and our solutions are designed to align with your specific risk appetite and strategic goals.</li>
                      
                    </ul>
                  </div>
                </div>

                
              </div>
            </div>

            {/* ......................................................... */}

            <div class="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8 md:mt-20">
              <div class="max-w-7xl mx-auto align-middle">
                <div class="text-left">
                  <h2 class="text-3xl font-bold text-blue-900 mb-6">
                  Key Outcomes for Clients
                  </h2>
                  
                </div>
                {/* <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                   
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 ">
                      Improved financial stability and accuracy in projections.
                      </h3>
                     
                    </div>
                  </div>
                  

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900  text-center ">
                        Valuation Actuary
                      </h3>
                      
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 ">
                        Pricing Actuary
                      </h3>
                      
                    </div>
                  </div>

                 

                
                </div> */}
                <div >
                <ul class="list-disc ml-6">
                      <li className="text-xl">Minimized financial and operational exposure to risks.</li>
                      <li className="text-xl">Enhanced resilience against market fluctuations and external uncertainties.</li>
                      <li className="text-xl">Confidence in regulatory compliance and governance standards.</li>
                      
                    </ul>
                </div>
                
              </div>
              
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}
